/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from 'reactstrap';

import Header from "components/header";
import 'style/index.global.scss';
// import "./layout.scss";

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <div style={{minHeight: '100vh'}} className="d-flex flex-column">

                <Header siteTitle={data.site.siteMetadata.title} />

                <div className="bg-dark text-white flex-grow-1 py-3 py-lg-5">
                    <Container>
                        <main>{children}</main>
                    </Container>
                </div>

                <footer className="bg-dark text-white text-right">
                    <Container>
                        <div style={{opacity: '.6'}}>
                            © {new Date().getFullYear()}{' '}
                            Samuele Santi
                        </div>
                    </Container>
                </footer>

            </div>

        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
