import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Container } from 'reactstrap';

import ProfilePic from './profile-pic';

const Header = ({ siteTitle }) => (
    <>

        <header>
            <Container>

                <div className="d-flex flex-column flex-lg-row align-items-lg-end justify-content-lg-between pt-3 pt-lg-5">
                    <div className="order-2 order-lg-1 text-center text-lg-left">
                        <h1 style={{ margin: 0, fontSize: '80px' }}>
                            <Link
                                to="/"
                                style={{color: '#000', textDecoration: `none`}}>
                                {siteTitle}
                            </Link>
                        </h1>
                    </div>
                    <div className="order-1 order-lg-2 text-center text-lg-right">
                        <ProfilePic style={{margin: '0 auto', display: 'block'}} />
                    </div>

                </div>
            </Container>
        </header>

        <div className="text-white" style={{background: '#000'}}>
            <Container>
                <div style={{minHeight: 80}}
                     className="d-lg-flex flex-row align-items-center justify-content-between">
                    <div className="text-center text-lg-left py-3 py-lg-5"
                         style={{fontSize: 36, textTransform: 'uppercase'}}>
                        Senior software engineer
                    </div>
                    <div className="text-center text-lg-right py-2">
                        <ContactDetails/>
                    </div>
                </div>
            </Container>
        </div>

    </>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;


function ContactDetails() {
    return <>
        <div>samuele@samuelesanti.com</div>
        <div>
            <a href="https://github.com/rshk">
                github.com/rshk
            </a>
        </div>
        <div>
            <a href="https://www.linkedin.com/in/samuelesanti/">
                linkedin.com/in/samuelesanti
            </a>
        </div>
        <div>Wexford, Ireland</div>
    </>;
}
