import React from "react";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";
import Img from "gatsby-image";


export default function ProfilePic(props) {
    const data = useStaticQuery(query);
    return <Img fixed={data.file.childImageSharp.fixed} {...props} />;
}


const query = graphql`
    query {
        file(
            sourceInstanceName: { eq: "images" },
            relativePath: { eq: "profile-pic.jpg" }) {

                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fixed(width: 300, height: 300, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
    }
`;
